/**
 * Global i18n configuration.
 * The currency setting (e.g. "EUR") only works with Stripe for now.
 * For LemonSqueezy, we need to set the currency in the LemonSqueezy dashboard
 * and there can only be one.
 */
export default defineI18nConfig(() => ({
  legacy: false,
  availableLocales: ["fr", "en"],
  locale: "fr",
  fallbackLocale: "en",
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard",
        maximumFractionDigits: 0,
      },
      number: {
        style: "decimal",
        maximumFractionDigits: 0,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    fr: {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard",
        maximumFractionDigits: 0,
      },
      number: {
        style: "decimal",
        maximumFractionDigits: 0,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
  },
}));
